<template>

    <span>

        <h3 style="text-align: center;">Weekly Report</h3>

        <div v-for="(audit, index) in audits.slice(0, 20)" :key="index" id="main-body" style="padding: 5px;">

            <span class="d-flex flex-row justify-content-between" style="align-items: center;">

                <span>
                    {{makeReportHumanReadable(audit)}}
                </span>

                <span>
                    {{convertToDateTime(audit.timestamp)}}
                </span>

            </span>

        </div>

    </span>

</template>

<script>
import digitalevidence from '../../../services/digital-evidence';
import iss from '../../../services/iss';

export default {
    name: "WeeklyReport",

    created() {
        this.getAudits();
    },

    data() {
        return {
            audits: []
        }
    },

    methods: {
        convertToDateTime(date) {
            let dateTime = new Date(date).toString();
            return dateTime.substring(dateTime.indexOf(' ') + 1, dateTime.indexOf('G') - 1);
        },
        getAudits() {
            let currentDate = new Date();
            let first = currentDate.getDate() - currentDate.getDay();
            let last = first + 6;
            let firstDay = parseInt((new Date(currentDate.setDate(first)).getTime()).toFixed(0));
            let lastDay = parseInt((new Date(currentDate.setDate(last)).getTime()).toFixed(0));
            digitalevidence.getAllAudits().then(response => {
                this.audits = response.data.filter(audit => {
                    return firstDay <= audit.timestamp && audit.timestamp <= lastDay;
                    // return audit.username === "joerock" && audit.endpoint !== '/evidence' && audit.endpoint !== '/evidence/download';
                });
            });
        },
        getUsers() {
            iss.getUsersInGroup();
        },
        makeReportHumanReadable(audit) {
            let action = '';
            let endpoint = '';
            if (audit.method === 'GET') {
                action = 'accessed';
            } else if (audit.method === 'POST') {
                action = 'created';
            }
            if (audit.endpoint === '/evidence') {
                endpoint = 'all evidence';
            } else if (audit.endpoint === '/evidence/:evidenceId') {
                endpoint = 'evidence ' + audit.evidenceId;
            } else if (audit.endpoint === '/evidence/download') {
                action = 'downloaded';
                endpoint = 'evidence with file ID ';
            } else if (audit.endpoint === '/case/user') {
                endpoint = 'user request';
            } else if (audit.endpoint === '/case') {
                endpoint = 'all cases';
            } else if (audit.endpoint === '/user/request') {
                endpoint = 'all user requests';
            } else if (audit.endpoint === '/user/request/waiting') {
                endpoint = 'all pending user requests';
            } else if (audit.endpoint === '/user/request/other') {
                endpoint = 'all non-pending user requests';
            } else if (audit.endpoint === '/case/other') {
                endpoint = 'all non-pending case requests';
            } else if (audit.endpoint === '/case/waiting') {
                endpoint = 'all pending cases';
            } else if (audit.endpoint === '/case/:caseRequestId') {
                endpoint = 'Case ' + audit.params.caseRequestId;
            }

            return 'User \'' + audit.username + '\' ' + action + ' ' + endpoint;
        }
    }
}
</script>

<style scoped>

@media print {

    #main-body {
        border: black 1px solid;
    }

    /*
    12 pt font
    Serif font?
    modify padding and margins with cm, mm, in
    use borders instead of background-colors
    */

}

</style>